//
// user.scss
// Use this to write your custom SCSS
//
// Net ReactionColor system

$NR-Red: #ca0016;
$NR-LightBlue: #678094;
$NR-DarkBlue: #3a5a6f;

.NR-Red {
  color: $NR-Red;
}

.NR-LightBlue {
  color: $NR-LightBlue;
}

.NR-DarkBlue {
  color: $NR-DarkBlue;
}

btn-NR-DarkBlue {
  background-color: $NR-DarkBlue;
  border-color: $NR-DarkBlue;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(22, 28, 45, 0.075);
  color: $NR-DarkBlue;
}

btn-NR-LightBlue {
  background-color: $NR-LightBlue;
  border-color: $NR-LightBlue;
  box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.15),
    0 1px 1px rgba(22, 28, 45, 0.075);
  color: #fff;
}

/* assessment styles */
.navbar {
  background-color: #fff;
}
.navbar-header {
  min-height: 105px;
  min-width: 380px;
  background-image: url(../img/logos/netreactionlogosmall.png);
  background-repeat: no-repeat;
}

.assessment-breadcrumb {
  height: 25px;
  background-color: #0e78b6;
}
.jumbotron {
  min-height: 510px;
  background: #fff;
}

.homepage_sub {
  color: #0e78b6;
}

.content_body {
  padding-top: 155px;
}

.assessment_intro {
  padding-bottom: 15px;
}

.assessment_question {
  padding-bottom: 10px;
}

.assessment_buttons {
  padding-top: 15px;
}

.assessment_QuestionHeader {
  color: #0e78b6;
}
